import * as React from 'react';
import "./ResourceList.css?__remix_sideEffect__";

const ResourceList = ({ heading, resources }) => {
  if (!heading) {
    console.error('ResourceList component requires a heading prop');
    return null;
  }

  const getDescriptionElement = (description) => {
    if (!description) {
      return false;
    }
    return <p className='copy'>{description}</p>;
  };

  return (
    <div className='ResourceList'>
      <h3 className='headline headline--alt headline--alt-sm'>{heading}</h3>
      <ul className='resources'>
        {resources.map((resource, index) => {
          return (
            <li className='resource' key={`resource-${index}`}>
              <a className='copy' href={resource.resourceLink.href} target={resource.resourceLink.target}>{resource.resourceTitle}</a>
              {getDescriptionElement(resource.description)}
            </li>);

        })}
      </ul>
    </div>);

};
export default ResourceList;